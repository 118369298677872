/* Custom fonts*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
    height: 100%;
    font-size: unset!important;
    /* Remove the following to get back to initial EUI bgColor */
    /*background-color: white!important;*/
}

/* Overrides EUI table vertical placement issue */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    vertical-align: middle!important;
}

#root {
    height: 99.9999999%;
}

/* Specific cases */
div[id="subcampaign"] thead {
    display:none;
}

.pricingBox { margin-top: 32px !important;}

@media (min-width: 959px) {
    .pricingBox {
        margin-top: -56px !important;
    }
}

input:disabled { opacity: 0 !important; }

/* Fancy scrolling to maintain visual consistency with website */
.fancyScroll::-webkit-scrollbar {
    width: 12px!important;
}

.fancyScroll::-webkit-scrollbar-track {
    background: #f1f1f1!important;
}

.fancyScroll::-webkit-scrollbar-thumb {
    background: #b2b2b2!important;
    border-radius: 8px!important;
    margin-left: 2px!important;
    margin-right: 2px!important;
}

.fancyScroll::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e!important;
}

.link {
    text-decoration: none!important;
}

.link:hover {
    text-decoration: underline!important;
}